export const Api = async (url, method = 'GET') => {
  try {
    const baseProd = 'https://api.relac.brasilstartups.org/'
    const baseLocal = 'http://localhost:4000/'
    
    const baseUrl = baseProd

    const response = await fetch(`${baseUrl}${url}`, {
      method,
    })
    
    return await response.json()
  } catch (error) {
    return error
  }
} 