import React from 'react'

import logoExec from '../assets/logo-exec.png'
import logoReal from '../assets/logo-real.png'

import '../styles/skeleton.css'
import '../styles/normalize.css'
import '../styles/components.css'

function Footer() {
  return (
    <div className="container">
      <div className="Footer">
        <div>
          <p>Execução</p>
          <img
            src={logoExec}
            alt="Brasil Startups"
            className="footer-logo"
          />
        </div>
        <div>
          <p>Realização</p>
          <img
            src={logoReal}
            alt="FAPDF"
            className="footer-logo"
          />
        </div>
      </div>
    </div>
  )
}

export default Footer
