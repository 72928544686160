import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../components/Header'
import Card from '../../components/Card'
import Footer from '../../components/Footer'
import '../../styles/skeleton.css'
import '../../styles/normalize.css'
import { Api } from '../../utils/api'

function Home() {
  const { id } = useParams()

  const [items, setItems] = useState([])
  const [title, setTitle] = useState('')

  useEffect(() => {
    handleLoadLinks()
  }, [id])

  const handleLoadLinks = async () => {
    try {
      const response = await Api(`participants/link/${id}`)

      setItems(response.links.filter(link => link))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="App" >
      <Header />
      <div className="container">
        <div className="row">
          {
            items.length > 0 && items?.map((item, key) => {
              return(
                <Card
                  key={key}
                  i={key}
                  title={item.title}
                  subtitle={item.subtitle}
                  link={item.link}
                  cover={item.image}
                />
              )
            })
          }
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Home
