import React from 'react'

import SunIcon from './Icons/SunIcon'
import MoonIcon from './Icons/MoonIcon'

import logo from '../assets/logo-main.png'

import '../styles/skeleton.css'
import '../styles/normalize.css'
import '../styles/components.css'

function Header() {
  const [theme, setTheme] = React.useState(() => document.body.getAttribute('data-theme') ?? 'dark')

  React.useEffect(() => {
    document.body.setAttribute('data-theme', theme)
  }, [theme])

  const handleSwitchTheme = () => {
    setTheme(isDark ? 'light' : 'dark')
  }

  const isDark = theme === 'dark'

  return (
    <div className="Header container">
      <div className="ten columns Header__inner">
        <img
          src={logo}
          alt="Startup Brasília 2030"
          className="logo"
        />
      </div>
      <button className="switch-theme-button" onClick={handleSwitchTheme}>
        {
          isDark
            ? <SunIcon color="white" />
            : <MoonIcon color="white" />
        }
      </button>
    </div>
  )
}

export default Header
